import { Link } from "react-router-dom";
import spanovi from "../data/spanovi.json";

const Span = ({ span, index }) => {
  return (
    <div className="col-md-3 col-xs-12">
      <div className="card">
        <img src={"/img/spanovi/" + span.image} alt="Avatar" />
        <div className="card-container">
          <div className="span-name">
            <h4>
              <b>
                {span.name} {span.debljina.join("/")} mm
              </b>
            </h4>
          </div>

          <div className="span-price-row">
            <div>
              <b>Španovanje</b>
            </div>
            <div className="span-price">
              20KM
            </div>
          </div>
          <div className="span-price-row">
            <div>
              <b>Žica 12m</b>
            </div>
            <div className="span-price">{span.price[0]}KM</div>
          </div>
          
          <br />
          <Link to={`/spanovi/${index}`}>
            <button className="btn btn-primary mb-3">Vidi više</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Spanovi = () => {
  return (
    <div>
      <div id="spanovi">
        <div className="container">
          <h2>Španovanje i Žice</h2>
          <h4 style={{fontWeight: 'bold', marginBottom: '25px'}}> Cijena španovanja 1 reket: 20KM</h4>
          <h4 style={{fontWeight: 'bold', marginBottom: '25px'}}> Cijena španovanja 2 ili vise reketa: 15KM/reket</h4>
          
          <div className="row">
            {spanovi
              .sort((a, b) => (a.price[0] < b.price[0] ? 1 : -1))
              .map((span, index) => (
                <Span key={span.name} index={index} span={span} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spanovi;
